<template>
  <div>
    <v-autocomplete
      v-model="input"
      :label="label"
      :items="skillsets"
      :multiple="skillset.length == 1 ? false : multiple"
      :loading="status.setGetting"
      :rules="required ? [rules.required] : []"
      :search-input.sync="skillsInput"
      @focus="getList()"
      @change="dataChanged()"
      :tabindex="tabindex ? tabindex : ''"
      :hide-details="hideDetails"
      :dense="dense"
      ref="skillField"
      v-on:input="limiter"
      append-icon="mdi-license"
      deletable-chips
      item-text="name"
      small-chips
      outlined
      chips
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import rules from "@/rules"

export default {
  props: {
    required: Boolean,
    multiple: Boolean,
    label: String,
    value: [String, Array],
    tabindex: Number,
    filter: Boolean,
    hideDetails: Boolean,
    dense: Boolean,
  },

  data() {
    return {
      rules,
      input: this.value,
      skillsInput: null,
    }
  },

  computed: {
    ...mapState({
      skillset: (state) => state.skillSets.skillSets,
      status: (state) => state.skillSets.status,
      qualified: (state) => state.profile.profile.skillset,
    }),

    skillsets: function () {
      let skillsets = this.skillset

      if (this.filter && this.qualified) {
        skillsets = skillsets.filter(skill => {
          return !this.qualified.includes(skill.name)
        })
      }

      return skillsets
    }
  },

  watch: {
    value: function (value) {
      try {
        if (value || value.length > 0) {
          if (!this.status.firstLoad) {
            this.getSkillSets()
          }
        }
      } catch (e) {
        console.log(e.message)
      }
    },
  },

  methods: {
    ...mapActions("skillSets", ["getSkillSets"]),

    getList() {
      if (!this.status.firstLoad) this.getSkillSets()
    },

    dataChanged() {
      this.$emit("update:value", this.input)
      this.skillsInput = null
    },

    limiter(e) {
      if (e.length > 20) {
        this.$refs.skillField._props.errorMessages.push(
          "Limit of 20 items in skillsets"
        )
        e.pop()
      } 
      else {
        this.$refs.skillField._props.errorMessages.forEach(function (
          item,
          index,
          object
        ) {
          object.splice(index, 1)
        })
      }
    },
  },

  created() {
    if (this.value && this.value.length > 0) {
      if (!this.status.firstLoad) {
        this.getSkillSets()
      }
    }
  },
}
</script>